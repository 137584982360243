@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500&display=swap');

body {
	background-color: #fff;
}
a {
	text-decoration: none;
	color: #333;
	transition: color 2s;
}

a:hover {
	color: #ff00d4;
	transition: color 0.25s;
}
h3 {
	font-size: 1.5em;
	font-weight: 500;
	margin: 0 0 4vh;
}
p {
	width: 100%;
	line-height: 1.6em;
	background-color: transparent;
	padding: 0;
	margin: 0 0 1.2em;
}
.App {
	max-width: 400px;
	padding: 0 1em;
	cursor: default;
	margin: auto;
}
header {
	margin: 0;
	padding: 0;
}
main {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: justify;
	line-height: 1.4em;
	font-size: 1rem;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-weight: 200;
	color: #666;
}

main p:nth-child(1) {
	font-weight: 500;
}
main p:nth-last-child(1) {
	text-align: end;
}
.input {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: space-between;
}
input,
button {
	width: auto;
	font-family: 'Rubik', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1em;
	font-weight: 400;
	padding: 0.5em 0;
	border: 1px solid #ccc;
	border-radius: 0.5em;
	margin-bottom: 1vh;
	text-align: center;
}
button {
	font-weight: 500;
	cursor: pointer;
}
button:hover {
	background-color: #ff79e9;
	color: #fff;
	transition: background-color 0.5s, color 0.5s;
}
.center {
	text-align: center !important;
}
footer {
	text-align: center;
	font-size: 0.6em;
	font-weight: 300;
	margin-bottom: 5vh;
}
.pl-50 {
	padding-left: 1em;
}
.pr-50 {
	padding-right: 1em;
}
.mb-100 {
	margin-bottom: 3vh;
}
.mb-300 {
	margin-bottom: 5vh;
}
.pointer {
	cursor: pointer;
}
